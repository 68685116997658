//const apiHost = 'http://localhost:8080';
const apiHost = "/profile/api";
const prefix = "https://";
const baseUrl = "arenation.com";
const env = "dev.";
export const environment = {
  production: false,
  apiUrl: prefix + env + baseUrl+ apiHost,
  baseUrl: baseUrl,
  assetsPath: "/assets",
  countryApi: prefix + env + baseUrl + "/countries/api/",
  authUrl: "https://auth." + env + baseUrl,
  env: env,
};
