import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-user-terms",
  templateUrl: "./user-terms.component.html"
})
export class UserTermsComponent {
  env = environment;

  constructor() {}
}
