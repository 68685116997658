import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
})
export class NotFoundComponent implements OnInit {
  env = environment;
  constructor() {}

  ngOnInit(): void {
    window.location.href = 'https://' + this.env.env + this.env.baseUrl+"/leaderboards";
  }
}
