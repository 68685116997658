<div class="welcome-container d-flex align-items-center mt-5">
  <div class="container rounded bg-white">
    <div class="p-5">
      <h1 align="center">PRIVACY POLICY</h1>
      <p align="justify">
        <em>Last updated April 12, 2024</em>
      </p>
      <p align="justify">
        This privacy notice for Arenation ("we," "us," or "our") describes how
        and why we might collect, store, use, and/or share ("process") your
        information when you use our services ("Services"), such as when you:
      </p>
      <ul>
        <li>
          Visit our website at arenation.com or any website of ours that links
          to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <p align="justify">
        Do you have questions or concerns? This privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have questions or concerns, please contact us at
        <a target="_blank" href="mailto:contact@arenation.com"
          >contact&#64;arenation.com</a
        >.
      </p>
      <h2>SUMMARY OF KEY POINTS</h2>
      <p align="justify">
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics below to find the
        section you are looking for.
      </p>
      <p align="justify">
        <strong>What personal information do we process?</strong>
        When you visit, use, or navigate our Services, we may process personal
        information depending on how you interact with us and the Services, the
        choices you make, and the products and features you use.
      </p>
      <p align="justify">
        <strong>Do we process any sensitive personal information?</strong>
        We do not process sensitive personal information.
      </p>
      <p align="justify">
        <strong>Do we receive any information from third parties?</strong>
        We may receive information from public databases, marketing partners,
        social media platforms, and other outside sources.&nbsp;
      </p>
      <p align="justify">
        <strong>How do we process your information?</strong>
        We process your information to provide, improve, and administer our
        Services, communicate with you for security and fraud prevention, and
        comply with the law. We may also process your information for other
        purposes with your consent. We process your data only when we have a
        valid legal reason.
      </p>
      <p align="justify">
        <strong
          >In what situations and with which types of parties do we share
          personal information?</strong
        >
        We may share information in specific situations and with specific
        categories of third parties.
      </p>
      <p align="justify">
        <strong>How do we keep your information safe?</strong>
        We have organizational and technical processes and procedures to protect
        your personal information. However, no electronic transmission over the
        internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information.
      </p>
      <p align="justify">
        <strong>What are your rights?</strong>
        Depending on where you are located geographically, the applicable
        privacy law may mean you have certain rights regarding your personal
        information.
      </p>
      <p align="justify">
        <strong>How do you exercise your rights?</strong>
        The easiest way is to submit a data subject access request or contact
        us. We will consider and act upon any request in accordance with
        applicable data protection laws.
      </p>
      <p align="justify">
        Want to learn more about what we do with any information we collect?
        Review the privacy notice in full.
      </p>
      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
      <h3>Personal information you disclose to us</h3>
      <p align="justify">
        <em
          >In Short: We collect personal information that you provide to us.</em
        >
      </p>
      <p align="justify">
        We collect personal information that you voluntarily provide us when you
        register on the Services, express an interest in obtaining information
        about us or our products and Services, participate in activities on the
        Services, or otherwise when you contact us.
      </p>
      <p align="justify">
        <strong>Personal Information Provided by You.</strong>
        The personal information we collect depends on the context of your
        interactions with us and the Services, your choices, and the products
        and features you use. The personal information we collect may include
        the following:
      </p>
      <ul>
        <li>names</li>
        <li>phone numbers</li>
        <li>email addresses</li>
        <li>usernames</li>
      </ul>
      <p align="justify">
        <strong>Sensitive Information.</strong>
        We do not process sensitive information.
      </p>
      <p align="justify">
        All personal information you provide must be true, complete, and
        accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h3>Information automatically collected</h3>
      <p align="justify">
        <em
          >In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services.</em
        >
      </p>
      <p align="justify">
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information). Still, it may include
        device and usage information, such as your IP address, browser and
        device characteristics, operating system, language preferences,
        referring URLs, device name, country, location, information about how
        and when you use our Services, and other technical details. This
        information is primarily needed to maintain the security and operation
        of our Services, as well as for our internal analytics and reporting
        purposes.
      </p>
      <p align="justify">
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>
      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p align="justify">
        <em
          >In Short: We process your information to provide, improve, and
          administer our Services, communicate with you for security and fraud
          prevention, and comply with the law. We may also process your
          information for other purposes with your consent.</em
        >
      </p>
      <p align="justify">
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <ul>
        <li>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </li>
        <li>
          To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </li>
        <li>
          To deliver targeted advertising to you. We may process your
          information to develop and display personalized content and
          advertising tailored to your interests, location, and more.
        </li>
        <li>
          To administer prize draws and competitions. We may process your
          information to administer prize draws and competitions.
        </li>
        <li>
          To evaluate and improve our Services, products, marketing, and your
          experience. We may process your information when we believe it is
          necessary to identify usage trends, determine the effectiveness of our
          promotional campaigns, and evaluate and improve our Services,
          products, marketing, and your experience.
        </li>
        <li>
          To identify usage trends. We may process information about how you use
          our Services to understand better how they are being used so we can
          improve them.
        </li>
        <li>
          To determine the effectiveness of our marketing and promotional
          campaigns. We may process your information to better understand how to
          provide marketing and promotional campaigns that are most relevant to
          you.
        </li>
      </ul>
      <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
      <p align="justify">
        <em
          >In Short: We may share information in specific situations described
          in this section and/or with the following categories of third
          parties.</em
        >
      </p>
      <p align="justify">
        Vendors, Consultants, and Other Third-Party Service Providers. We may
        share your data with third-party vendors, service providers,
        contractors, or agents ("third parties") who perform services for us or
        on our behalf and require access to such information to do that work. We
        have contracts with our third parties, designed to help safeguard your
        personal information. This means they cannot do anything with your
        personal information unless we have instructed them to do it. They will
        also not share your personal information with any organization apart
        from us. They also commit to protecting the data they hold on our behalf
        and retaining it for the period we instruct. The categories of third
        parties we may share personal information with are as follows:
      </p>
      <ul>
        <li>Ad Networks</li>
        <li>Sales &amp; Marketing Tools</li>
        <li>Social Networks</li>
      </ul>
      <p align="justify">
        We also may need to share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          Business Transfers: We may share or transfer your information in
          connection with or during negotiations of, any merger, sale of company
          assets, financing, or acquisition of all or a portion of our business
          to another company.
        </li>
        <li>
          Business Partners: We may share your information with our business
          partners to offer certain products, services, or promotions.
        </li>
      </ul>
      <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
      <p align="justify">
        <em
          >In Short: We may use cookies and other tracking technologies to
          collect and store your information.</em
        >
      </p>
      <p align="justify">
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Our Cookie Notice provides
        specific information about how we use such technologies and how you can
        refuse certain cookies.
      </p>
      <h2>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
      <p align="justify">
        <em
          >In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.</em
        >
      </p>
      <p align="justify">
        Our Services allow you to register and log in using your third-party
        social media account details (like your Facebook or Twitter logins).
        Where you choose to do this, we will receive certain profile information
        about you from your social media provider. The profile information we
        receive may vary depending on the social media provider concerned but
        will often include your name, email address, friends list, profile
        picture, and other information you choose to make public on such a
        social media platform.
      </p>
      <p align="justify">
        We will use the information we receive only for the purposes described
        in this privacy notice or that are otherwise made clear to you regarding
        the relevant Services. Please note that we do not control and are not
        responsible for, other uses of your personal information by your
        third-party social media provider. We recommend reviewing their privacy
        notice to understand how they collect, use, and share your personal
        information and how you can set your privacy preferences on their sites
        and apps.
      </p>
      <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p align="justify">
        <em
          >In Short: We keep your information for as long as necessary to
          fulfill the purposes outlined in this privacy notice unless otherwise
          required by law.</em
        >
      </p>
      <p align="justify">
        We will only keep your personal information for as long as necessary for
        the purposes set out in this privacy notice unless a longer retention
        period is required or permitted by law (such as tax, accounting, or
        other legal requirements). No purpose in this notice will require us to
        keep your personal information for longer than the period of time in
        which users have an account with us.&nbsp;
      </p>
      <p align="justify">
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize it. If this is
        not possible (for example, because your personal information has been
        stored in backup archives), we will securely store it and isolate it
        from any further processing until deletion is possible.
      </p>
      <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p align="justify">
        <em
          >In Short: We aim to protect your personal information through
          organizational and technical security measures.</em
        >
      </p>
      <p align="justify">
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p align="justify">
        <em
          >In Short:&nbsp; You may review, change, or terminate your account
          anytime.</em
        >
      </p>
      <p align="justify">
        <strong>Withdrawing your consent:</strong>
        If we rely on your consent to process your personal information, which
        may be express and/or implied, depending on the applicable law, you can
        withdraw your consent at any time by contacting us using the contact
        details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?" below.
      </p>
      <p align="justify">
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor when applicable law allows, will it
        affect the processing of your personal information conducted in reliance
        on lawful processing grounds other than consent.
      </p>
      <p align="justify">
        <strong>Opting out of marketing and promotional communications:</strong>
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send
        or by contacting us using the details provided in the section "HOW CAN
        YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from
        the marketing lists. However, we may still communicate with you — for
        example, to send you service-related messages necessary for the
        administration and use of your account, to respond to service requests,
        or for other non-marketing purposes.
      </p>
      <h3>Account Information</h3>
      <p align="justify">
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li>Log in to your account settings and update your user account.</li>
        <li>Contact us using the contact information provided.</li>
      </ul>
      <p align="justify">
        Upon your request to terminate your account, we will deactivate or
        delete it and the information in it from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with investigations, enforce our legal
        terms, and/or comply with applicable legal requirements.
      </p>
      <p align="justify">
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. However, you can usually set your browser to remove
        or reject cookies. If you choose to remove or reject cookies, this could
        affect certain features or services of our Services.
      </p>
      <p align="justify">
        If you have questions or comments about your privacy rights, you may
        email us at
        <a target="_blank" href="mailto:contact@arenation.com"
          >contact&#64;arenation.com</a
        >.
      </p>
      <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p align="justify">
        Most web browsers, mobile operating systems, and mobile applications
        include a Do-Not-Track ("DNT") feature or setting you can activate to
        signal your privacy preference and not to have data about your online
        browsing activities monitored and collected. At this stage, no uniform
        technology standard for recognizing and implementing DNT signals has
        been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <h2>10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p align="justify">
        <em
          >In Short: Yes, we will update this notice to stay compliant with
          relevant laws.</em
        >
      </p>
      <p align="justify">
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and will be effective as
        soon as it is accessible. If we make material changes to this privacy
        notice, we may notify you either by prominently posting a notice or by
        directly sending you a notification. We encourage you to review this
        privacy notice frequently to be informed of how we are protecting your
        information.
      </p>
      <h2>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p align="justify">
        If you have questions or comments about this notice, you may email us at
        <a target="_blank" href="mailto:contact@arenation.com"
          >contact&#64;arenation.com</a
        >.
      </p>
      <h2>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p align="justify">
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request at
        <a target="_blank" href="mailto:contact@arenation.com"
          >contact&#64;arenation.com</a
        >.
      </p>
    </div>
  </div>
</div>
