import { InjectionToken, NgModule } from "@angular/core";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { UserTermsComponent } from "./user-terms/user-terms.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ActivatedRouteSnapshot, RouterModule, Routes } from "@angular/router";

const externalUrlProvider = new InjectionToken("externalUrlRedirectResolver");
const deactivateGuard = new InjectionToken("deactivateGuard");

const routes: Routes = [
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "user-terms",
    component: UserTermsComponent,
  },
  {
    path: "**",

    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
